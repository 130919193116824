import React, { useEffect, useRef, useState } from 'react';
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import "./Nav.css";
import { Link } from 'react-router-dom';

export default function NavbarComponent(props) {


    //reference of navbar
    const [expanded, setExpand] = useState(false)
    const navigation = useRef();
    const [scrollPos, setScrollPos] = useState(props.scrollTop);
    // const keys = { "/": home, "/wentylacja": wentylacja, "/klimatyzacja": klimatyzacja, "/pompy": pompa }

    useEffect(() => {
        // hide navbar while scrolling down
        if (props.scrollTop > (scrollPos + 0.6)) {
            //if website scrolled enought
            if (props.scrollTop > 15) {
                navigation.current.style.opacity = '0';
                navigation.current.style.transform = 'translate(0, -100%)';
            }
        }
        // if scrolling up show navbar
        else if ((props.scrollTop + 0.2) < scrollPos) {
            navigation.current.style.opacity = '1';
            navigation.current.style.transform = 'translate(0, 0)'
        }
        //set 'last pos' of scroll (to check if  user is still scrolling or not)
        const debounceScroll = setTimeout(() => {
            setScrollPos(props.scrollTop)
        }, 100)
    }, [props.scrollTop])



    const [pompy, setPompyShow] = useState(false);
    //status listen to change of hovering element and triggers debounce
    const [pompyStatus, setPompyStatus] = useState(false);


    return (
        <div ref={navigation} className={`navbarBody  ${props.scrollTop >= 3 ? 'navShadow' : ''}`}>
            <Navbar collapseOnSelect  expand="md" className='p-0'>
                <Container className={` ${props.scrollTop >= 3 ? 'navSizeSM navSize' : 'navSize'}`}>
                    <Navbar.Brand href="/" className='m-2' >
                        <img src={require("../../images/Logo_Eledyn_poziom_ciemne.png")} alt='Koło naukowe Eledyn' />
                    </Navbar.Brand>
                    <Navbar.Toggle className='h-75' aria-controls="main-nav" />
                    <Navbar.Collapse className={`${props.scrollTop >= 3 ? 'navSizeSM navSize' : 'navSize'}`} id="main-nav">
                        <Nav className="mx-auto column-gap-5" style={{ height: '100px !important' }}>
                        <Nav.Link eventKey={1} className=" mobile fs-5 udnerlinehover">
                                <Link onClick={()=> {window.scrollTo({ top: 0, behavior: 'smooth' })}} to="/" className='nav-link'>
                                    Plan&nbsp;sympozjum
                                </Link>
                            </Nav.Link>
                            <Nav.Link eventKey={2} className=" mobile fs-5 udnerlinehover">
                                <Link onClick={()=> {window.scrollTo({ top: 0, behavior: 'smooth' })}} to="/zapisy" className='nav-link'>
                                    Zapisz&nbsp;się
                                </Link>
                            </Nav.Link>
                        </Nav>

                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    );
};