import React from "react";
import "./plan.css"
import Aos from "aos";
import { Col } from "react-bootstrap";

const Plan = () => {
    return (
        <div className=" w-100 h-100 pt-5">
            <div data-aos="fade-right" className="side-label">
                <img src={require("../../images/WEAiI_ uprosz_poziom_czarne.png")} alt="logo eledyn" />
            </div>
            <div className="w-100  d-flex header-con mb-5">

                <div className="header1 ps-2  ps-sm-5 ">
                    <h1 className="ps-2 ps-sm-2 ps-xl-5 mb-0">
                        <span className="top-text">XXV&nbsp;Lecie </span><br />
                        <span className="bottom-text">Koła&nbsp;naukowego</span>
                    </h1>
                    <div className="underline w-25 ms-2 ms-sm-2 ms-xl-5">
                        <div ></div>
                    </div>
                </div>
                <div className="header-image d-flex justify-content-center pe-1 pe-sm-5">
                    <img data-aos="zoom-in" src={require("../../images/Logo_Eledyn_pion_białe.png")} alt="koło naukowe eledyn" />
                </div>

            </div>
            <div className="ps-2  ps-sm-5 mt-5 mb-2 mb-sm-5 me-2" >
                <div className="ps-2 ps-sm-2 ps-xl-5 d-flex flex-wrap column-gap-5 basic-info ">
                    <div>
                        <span>
                            28.06.2024 <br />
                            PIĄTEK
                        </span>
                    </div>
                    <div className=" vl"></div>

                    <div>
                        <span>
                            9:00&nbsp;-&nbsp;16:00
                        </span>
                    </div>

                    <div className="vl"></div>
                    <div>
                        <span>
                            <span className="fs-6">
                                POLITECHNIKA&nbsp;OPOLSKA
                            </span>
                            <br />
                            P9 - SALA 25

                        </span>
                    </div>

                </div>
            </div>
            <div className="px-2  px-sm-5 mt-0 mt-md-5 " >
                <div className="px-2 px-sm-2 px-xl-5 text-con py-3 py-sm-5" data-aos="fade-up">
                    <h2 className="fs-1 mb-5">Plan wydarzenia</h2>
                    <h3>Część oficjalna</h3>
                    <div className="d-flex justify-content-center flex-wrap">
                        <Col className="col-12 col-sm-3 col-md-2 text-sm-end p-3 border-end border-top border-bottom">
                            9:00
                        </Col>
                        <Col className="pb-5 pb-sm-1 col-12 col-sm-9 col-md-10 p-3 border-bottom border-top">
                            Oficjalne rozpoczęcie i powitanie uczestników
                        </Col>
                    </div>
                    <div className="d-flex justify-content-center flex-wrap bg-lighter">
                        <Col className="col-12 col-sm-3 col-md-2 text-sm-end p-3 border-end border-bottom">
                            9:15
                        </Col>
                        <Col className="col-12 col-sm-9 col-md-10 p-3 border-bottom">
                            Przemówienie władz uczelni (Rektor, Prorektor, Dziekan) oraz opiekunów koła naukowego (Prof. Bronisław Tomczuk, prof. Andrzej Waindok
                        </Col>
                    </div>
                    <div className="d-flex justify-content-center flex-wrap">
                        <Col className="col-12 col-sm-3 col-md-2 text-sm-end p-3 border-end border-bottom">
                            10:00
                        </Col>
                        <Col className="col-12 col-sm-9 col-md-10 p-3 border-bottom">
                            Prezentacja historii Koła Naukowego ELEDYN
                        </Col>
                    </div>
                    <div className="d-flex justify-content-center flex-wrap bg-lighter">
                        <Col className="col-12 col-sm-3 col-md-2 text-sm-end p-3 border-end border-bottom">
                            10:40
                        </Col>
                        <Col className="col-12 col-sm-9 col-md-10 p-3 border-bottom">
                        Prezentacja obecnych oraz przyszłych planów i projektów koła
                        </Col>
                    </div>
                    <div className="d-flex justify-content-center flex-wrap">
                        <Col className="col-12 col-sm-3 col-md-2 text-sm-end p-3 border-end border-bottom">
                            11:00
                        </Col>
                        <Col className="col-12 col-sm-9 col-md-10 p-3 border-bottom">
                        Przerwa kawowa
                                                </Col>
                    </div>
                    <div className="d-flex justify-content-center flex-wrap bg-lighter">
                        <Col className="col-12 col-sm-3 col-md-2 text-sm-end p-3 border-end border-bottom">
                            11:30
                        </Col>
                        <Col className="col-12 col-sm-9 col-md-10 p-3 border-bottom">
                        Prezentacje sponsorów i zaproszonych gości (MEB Group, KBA Automatic, Explomet, Adam Hadala)                        </Col>
                    </div>
                    <div className="d-flex justify-content-center flex-wrap">
                        <Col className="col-12 col-sm-3 col-md-2 text-sm-end p-3 border-end border-bottom">
                            13:30
                        </Col>
                        <Col className="col-12 col-sm-9 col-md-10 p-3 border-bottom">
                        Obiad                        </Col>
                    </div>
                    <div className="d-flex justify-content-center flex-wrap mb-5 bg-lighter ">
                        <Col className="col-12 col-sm-3 col-md-2 text-sm-end p-3 border-end border-bottom">
                            15:00
                        </Col>
                        <Col className="col-12 col-sm-9 col-md-10 p-3 border-bottom">
                        Zakończenie części oficjalnej
                        </Col>
                    </div>

                    <h3 className="mt-5">Część nieoficjalna</h3>
                    <p>miejsce: Karczma Zagłoba, Opole ul. Strzelecka 80c</p>
                    <div className="d-flex justify-content-center flex-wrap">
                        <Col className="col-12 col-sm-3 col-md-2 text-sm-end p-3 border-end border-top border-bottom">
                            17:00
                        </Col>
                        <Col className="pb-5 pb-sm-1 col-12 col-sm-9 col-md-10 p-3 border-bottom border-top">
                            Rozpoczęcie
                        </Col>
                    </div>
                    <div className="d-flex justify-content-center flex-wrap bg-lighter">
                        <Col className="col-12 col-sm-3 col-md-2 text-sm-end p-3 border-end border-bottom">
                            17:15
                        </Col>
                        <Col className="col-12 col-sm-9 col-md-10 p-3 border-bottom">
                        Posiłek w formie grilla z zapewnionymi napojami                        </Col>
                    </div>
                    <div className="d-flex justify-content-center flex-wrap">
                        <Col className="col-12 col-sm-3 col-md-2 text-sm-end p-3 border-end border-bottom">
                            23:00
                        </Col>
                        <Col className="col-12 col-sm-9 col-md-10 p-3 border-bottom">
                        Zakończenie spotkania                        </Col>
                    </div>

                </div>




            </div >
        </div >
    )
}
export default Plan