import React from "react";
import "./footer.css"
import { Link } from "react-router-dom";
const Footer = () => {
    return (
        <div  className="main w-100  p-0 m-0  py-3">

            <h3 className="ms-3 ms-sm-5 fs-4">Nasi partnerzy:</h3>
            <div className="d-flex justify-content-center justify-content-md-between flex-wrap">

                <div className="my-3 footer-img col-6 col-md-2 d-flex align-items-center justify-content-center">
                    <a href="https://www.kba-automatic.pl/">
                        <img src={require("../../images/logoKBA.png")} alt="KBA automatic" />
                    </a>
                </div>
                <div className="my-3 footer-img col-6 col-md-2 d-flex align-items-center justify-content-center">
                    <a href="https://meb-group.com/">
                        <img src={require("../../images/MEB_logo.jpg")} alt="meb group" />
                    </a>
                </div>
                <div className="my-3 footer-img col-6 col-md-2 d-flex align-items-center justify-content-center">
                    <a href="https://sep.com.pl/">
                        <img className="p-4" src={require("../../images/sep.png")} alt="magneto" />
                    </a>
                </div>
                <div className="my-3 footer-img col-6 col-md-2 d-flex align-items-center justify-content-center">
                    <a href="https://www.explomet.pl/">
                        <img src={require("../../images/explomet.png")} alt="politechnika opolska" />
                    </a>
                </div>
                <div className="my-3 footer-img col-6 col-md-3 d-flex align-items-center justify-content-center">
                    <a href="https://po.edu.pl/">
                        <img src={require("../../images/logotyp-politechnika-opolska-biale.png")} alt="politechnika opolska" />
                    </a>
                </div>
              
            </div>
            <div className="w-100  d-flex justify-content-center">
                <div className="hr"></div>
            </div>
            <div className="px-5  d-flex justify-content-center gap-4 py-4 ">
                <Link to="/polityka-prywatnosci" onClick={()=> {window.scrollTo({ top: 0, behavior: 'smooth' })}}>
                Polityka prywatności
                </Link>
                <Link to="/deklaracja-dostepnosci" onClick={()=> {window.scrollTo({ top: 0, behavior: 'smooth' })}}>
                Deklaracja dostępności
                </Link>
                {/* <a className="text-center" href="/polityka-prywatnosci"></a>
                <a className="text-center" href="/deklaracja-dostepnosci">Deklaracja dostępności</a>
 */}

            </div>
            <div className="px-5 d-flex justify-content-center pt-4">

                <span className="mx-5">Copyright&nbsp;&copy;&nbsp;Eledyn&nbsp;2024</span>

            </div>

        </div>
    )
}

export default Footer