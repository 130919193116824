import React, { useRef } from "react";
import "./zapisy/zapisy.css"

const Dostepnosc = () => {



    return (
        <div className="d-flex flex-wrap justify-content-center w-100 h-100 mb-5">
            <div className="px-2 w-100 px-sm-5 mt-0 mt-md-5 " >
            <div className="info-parent px-2 w-100 px-sm-2 px-xl-5 text-con py-3 py-sm-5" data-aos="fade-up">

                <header>
                    <h1 className="mb-5">Deklaracja dostępności</h1>
                </header>
                <main>
                    <section>
                        <p>Politechnika Opolska zobowiązuje się zapewnić dostępność swojej strony internetowej zgodnie z przepisami ustawy z dnia 4 kwietnia 2019 r. o dostępności cyfrowej stron internetowych i aplikacji mobilnych podmiotów publicznych. Oświadczenie w sprawie dostępności ma zastosowanie do strony internetowej Politechniki Opolskiej.</p>
                        <p><strong>Data publikacji strony internetowej:</strong> 2024-05-24</p>
                    </section>

                    <section>
                        <h2>Status pod względem zgodności z ustawą</h2>
                        <p>Strona internetowa jest częściowo zgodna z ustawą z dnia 4 kwietnia 2019 r. o dostępności cyfrowej stron internetowych i aplikacji mobilnych podmiotów publicznych z powodu niezgodności lub wyłączeń wymienionych poniżej:</p>
                        <ul>
                            <li>Strona posiada elementy niezgodne ze specyfikacją HTML.</li>
                            <li>Strona posiada obszary, które po powiększeniu zaburzają odczyt treści.</li>
                            <li>Na stronie osadzone są filmy z serwisu YouTube, które nie posiadają audiodeskrypcji.</li>
                        </ul>

                       
                      </section>

                    <section>
                        <h2>Skróty klawiaturowe</h2>
                        <p>Na stronie nie zastosowano żadnych specjalnych skrótów klawiaturowych. Można używać standardowych skrótów klawiaturowych przeglądarki.</p>
                    </section>

                    <section>
                        <h2>Informacje zwrotne i dane kontaktowe</h2>
                        <p>W przypadku problemów z dostępnością strony internetowej prosimy o kontakt. Osobą kontaktową jest Łukasz Kępa, e-mail: <a href="mailto:l.kepa@student.po.edu.pl">l.kepa@student.po.edu.pl</a></p>
                    </section>

                    <section>
                        <h2>Procedura wnioskowo-skargowa</h2>
                        <p>Każdy ma prawo do wystąpienia z żądaniem zapewnienia dostępności cyfrowej strony internetowej, aplikacji mobilnej lub jakiegoś ich elementu. Można także zażądać udostępnienia informacji za pomocą alternatywnego sposobu dostępu, na przykład przez odczytanie niedostępnego cyfrowo dokumentu, opisanie zawartości filmu bez audiodeskrypcji itp.</p>
                        <p>Żądanie powinno zawierać dane osoby zgłaszającej żądanie, wskazanie, o którą stronę internetową lub aplikację mobilną chodzi oraz sposób kontaktu. Jeżeli osoba żądająca zgłasza potrzebę otrzymania informacji za pomocą alternatywnego sposobu dostępu, powinna także określić dogodny dla niej sposób przedstawienia tej informacji.</p>
                        <p>Podmiot publiczny powinien zrealizować żądanie niezwłocznie, nie później niż w ciągu 7 dni od dnia wystąpienia z żądaniem. Jeżeli dotrzymanie tego terminu nie jest możliwe, podmiot publiczny niezwłocznie informuje o tym wnoszącego żądanie, kiedy realizacja żądania będzie możliwa, przy czym termin ten nie może być dłuższy niż 2 miesiące od dnia wystąpienia z żądaniem.</p>
                        <p>Jeżeli zapewnienie dostępności cyfrowej nie jest możliwe, podmiot publiczny może zaproponować alternatywny sposób dostępu do informacji. W przypadku, gdy podmiot publiczny odmówi realizacji żądania zapewnienia dostępności lub alternatywnego sposobu dostępu do informacji, wnoszący żądanie może złożyć skargę w sprawie zapewnienia dostępności cyfrowej strony internetowej, aplikacji mobilnej lub elementu strony internetowej, lub aplikacji mobilnej. Po wyczerpaniu wskazanej wyżej procedury można także złożyć wniosek do Rzecznika Praw Obywatelskich.</p>
                    </section>
                </main>
            </div>
        </div >
        </div>
    )
}
export default Dostepnosc