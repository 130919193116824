import React, { useRef } from "react";
import "./zapisy/zapisy.css"


const Polityka = () => {



    return (
        <div className="d-flex flex-wrap justify-content-center w-100 h-100 mb-5">
            <div className="px-2 w-100 px-sm-5 mt-0 mt-md-5 " >

                <div className="info-parent px-2 w-100 px-sm-2 px-xl-5 text-con py-3 py-sm-5" data-aos="fade-up">


                    <header className="mb-5">
                        <h1>Polityka Prywatności</h1>
                                            </header>

                    <section id="informacje-ogolne">
                        <h2>Informacje ogólne</h2>
                        <p>Od 25 maja 2018 roku jest stosowane Rozporządzenie Parlamentu Europejskiego i Rady UE 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (RODO).</p>
                        <p>W ramach tych zmian informujemy, że na naszych stronach wykorzystywane są mechanizmy (pliki „cookies”) służące do zbierania i przetwarzania danych osobowych. Ich zbieranie umożliwia nam świadczenie wysokiej jakości usług informatycznych dotyczących naszych stron WWW oraz usprawnienie ich działania. Stosujemy je w celu analizowania ruchu na naszych stronach.</p>
                        <p>Strony Politechniki Opolskiej korzystają z plików „cookies” w celach określonych w poniższej polityce. Warunki przechowywania lub dostępu do „cookie” można określić w stosowanym oprogramowaniu, np. przeglądarce internetowej.</p>
                        <p>Korzystanie ze stron Politechniki Opolskiej bez zmiany ustawień oprogramowania (np. przeglądarki internetowej), klikając w przycisk „Zgadzam się” lub zamykając okienko informacyjne dotyczące polityki prywatności na naszych stronach, oznacza zgodę na używanie mechanizmów wykorzystywanych na naszych stronach służących do zbierania danych oraz na zawarte poniżej postanowienia.</p>
                    </section>

                    <section id="administrator-danych-osobowych">
                        <h2>Administrator danych osobowych</h2>
                        <p>Administratorem danych osobowych jest zarząd koła naukowego Eledyn, politechniki opolskiej</p>
                        <p>Kontakt do Inspektora Danych Osobowych: e-mail: <a href="mailto:l.kepa@student.po.edu.pl">l.kepa@student.po.edu.pl</a></p>
                    </section>

                    <section id="pliki-cookies">
                        <h2>Pliki „cookies”</h2>
                        <p>Pliki cookies (tzw. „ciasteczka”) stanowią dane informatyczne, w szczególności pliki tekstowe, które przechowywane są w urządzeniu końcowym użytkownika serwisu i przeznaczone są do korzystania ze stron internetowych serwisu. Pliki „cookies” zazwyczaj zawierają nazwę strony internetowej, z której pochodzą, czas przechowywania ich na urządzeniu końcowym, adres IP oraz unikalny numer.</p>
                        <p>Stosowane przez nas pliki „cookies” służą przede wszystkim do optymalizacji korzystania ze stron internetowych Politechniki Opolskiej, tworzenia statystyk ich odwiedzin oraz do utrzymania sesji użytkownika po jego zalogowaniu się do określonych stron (brak konieczności ponownego wpisywania loginu i hasła w określonym czasie trwania sesji).</p>
                        <p>Na naszych stronach stosowane są „cookies” sesyjne, czyli pliki tymczasowymi, które przechowywane są w urządzeniu końcowym odwiedzającego stronę do czasu jego wylogowania, opuszczenia strony internetowej lub wyłączenia oprogramowania (np. przeglądarki internetowej).</p>
                        <p>Stosowane przez nas również zewnętrzne pliki „cookie” umożliwiają współpracę naszych stron z serwisami zewnętrznymi. Wśród nich są „cookie” badawcze dostarczające systemom analitycznym dane o popularności usługi (statystyki na temat korzystania z naszej strony – Google Analytics) oraz pliki związane z usługami pochodzącymi z serwisów społecznościowych lub multimedialnych (m.in. Facebook, Twitter, Google+, YouTube).</p>
                    </section>

                    <section id="blokowanie-cookies">
                        <h2>Blokowanie plików "cookies"</h2>
                        <p>Przeglądarki internetowe, czyli standardowe oprogramowanie służące do przeglądania stron internetowych domyślnie dopuszczają używanie plików „cookies”. Jednak ustawienia te mogą zostać zmienione w taki sposób, aby blokować automatyczną obsługę plików „cookies” w ustawieniach przeglądarki internetowej bądź informować o ich każdorazowym przesłaniu na urządzenie użytkownika. Szczegółowe informacje o możliwości i sposobach obsługi plików „cookies” dostępne są w ustawieniach oprogramowania, np. przeglądarki internetowej.</p>
                        <p><strong>Uwaga:</strong> Ograniczenie stosowania plików „cookies” może wpłynąć na funkcjonalność stron Politechniki Opolskiej.</p>
                    </section>

                    <section id="przekazywanie-danych">
                        <h2>Przekazywanie danych</h2>
                        <p>Zbierane dane wykorzystujemy do celów własnych, ale mogą one być przekazywane innym podmiotom w celu optymalizacji korzystania z naszych stron internetowych, tworzenia statystyk ich odwiedzin (Google Analytics) lub wykorzystania wtyczek i innych narzędzi pochodzących z serwisów społecznościowych lub multimedialnych (m.in. Facebook, Twitter, Google+, YouTube).</p>
                    </section>

                    <section id="dostep-do-danych">
                        <h2>Dostęp do danych oraz uprawnienia</h2>
                        <p>Każdy użytkownik naszych stron ma prawo zażądać dostępu do jego danych w celu ich sprostowania, uzupełnienia, przeniesienia danych oraz zażądać usunięcia jego danych osobowych lub ograniczyć ich przetwarzanie.</p>
                        <p>Użytkownikowi przysługuje również prawo wniesienia sprzeciwu względem przetwarzania jego danych osobowych jak również prawo wniesienia skargi do organu nadzorczego zajmującego się ochroną danych osobowych – PUODO.</p>
                    </section>

                    <section id="wyrazenie-zgody">
                        <h2>Wyrażenie zgody</h2>
                        <p>Wyrażenie zgody jest dobrowolne i polega na kliknięciu w przycisk „Zgadzam się” lub zamykając okienko informacyjne dotyczące polityki prywatności na naszych stronach. W każdym momencie możesz ją wycofać poprzez skasowanie plików „cookies” oraz dokonanie zmian ustawień oprogramowania, np. przeglądarki internetowej.</p>
                    </section>

                </div>
            </div>
        </div >
    )
}
export default Polityka