import {
  createBrowserRouter,
  createHashRouter,
  RouterProvider,
  Routes, Route
} from "react-router-dom"; import './App.css';
import React, {useState} from "react";
import Plan from "./pages/plan/plan";
import Aos from "aos";
import "aos/dist/aos.css"
import Zapisy from "./pages/zapisy/zapisy";
import { useEffect } from "react";
import Footer from "./components/footer/footer";
import NavbarComponent from "./components/navbar/Navbar";

import Scrollanimations from "./components/navbar/scrollAnimation"
import Dostepnosc from "./pages/dostepnosc";
import Polityka from "./pages/polityka";

function App() {

  
  const [scrollTop, setScrollTop] = useState(0);

  Scrollanimations(scrollTop, setScrollTop);

  useEffect(() => {
    Aos.init();
  }, [])

  return (
    <div className="m-0 p-0 d-flex flex-column flex-wrap m-auto justify-content-center">
      <div className="w-100  d-flex justify-content-center">
        <div  style={{ maxWidth: "2300px", width : "100vw" }}>

            <NavbarComponent scrollTop={scrollTop}/>
          <div className=' pt-5 w-100 h-100 main-con'>
            <Routes>
            <Route path="/" element={<Plan/>}/>
        <Route path="/zapisy" element={<Zapisy/>}/>
        <Route path="/deklaracja-dostepnosci" element={<Dostepnosc/>}/>
        <Route path="/polityka-prywatnosci" element={<Polityka/>}/>
        <Route path="/*" element={<Plan/>}/>
            </Routes>
          </div>
        </div>
      </div>
      <div className="w-100 d-flex justify-content-center">
        <div   className="pb-0 w-100" style={{ maxWidth: "2300px" }}>
          <Footer />
        </div>
      </div>
    </div>

  );
}

export default App;
