import React, { useRef } from "react";
import "./zapisy.css"
import emailjs from '@emailjs/browser';
import { Button } from "bootstrap";


const Zapisy = () => {

    const form = useRef();
    const button = useRef();

    const sendEmail = (e) => {
        button.current.disabled = true
        button.current.innerHTML = '<div class="spinner-border" role="status"></div>'

        e.preventDefault();

        emailjs
            .sendForm('service_tspyuki', 'template_mj90vty', form.current, {
                publicKey: 'J2IJ8QWwUNNkxXOE6',
            })
            .then(
                () => {
                    document.querySelector("form").classList.add("form-sended")
                    document.querySelector("div.info").style.visibility = "visible"
                },
                (error) => {
                    document.querySelector("form").classList.add("form-sended")
                    document.querySelector("div.error").style.visibility = "visible"
                },
            );
    }
    return (
        <div className="d-flex flex-wrap justify-content-center w-100 h-100 mb-5">
            <div className="px-2 w-100 px-sm-5 mt-0 mt-md-5 " >
                <div className="px-2 w-100 px-sm-2 px-xl-5  py-3 py-sm-5 " >
                    <div data-aos="fade-right">

                        <h1>Zapisz się</h1>
                        <h2 className="mb-4">Aby wziąć udział w sympozjum należy: </h2>
                    </div>
                    <div data-aos="fade-up" className="info-parent px-2 w-100 px-sm-2 p-xl-5 text-con py-3 py-sm-5">
                        <h2>1. Wypełnić formularz</h2>
                        <div className=" d-flex justify-content-center pt-4">

                            <form className="form" ref={form} onSubmit={sendEmail} style={{ maxWidth: "1000px", width: "100%" }}>
                                <div className="d-flex flex-wrap w-100 ">
                                    <div className="mb-3 col-12 col-md-6 pe-md-2">
                                        <label for="name" className="form-label">Imię<span className="bg-req">*</span></label>
                                        <input required name="name" type="text" className="form-control" />
                                    </div>
                                    <div className="mb-3 col-12 col-md-6 ps-md-2">
                                        <label for="surname" className="form-label">Nazwisko<span className="bg-req">*</span> </label>
                                        <input required name="surname" type="text" className="form-control" />
                                    </div>
                                </div>
                                <div className="d-flex flex-wrap w-100 ">
                                    <div className="mb-3 col-12 col-md-6 pe-md-2">
                                        <label for="email" className="form-label">Adres email<span className="bg-req">*</span> </label>
                                        <input required name="email" type="email" className="form-control" />
                                    </div>
                                    <div className="mb-3 col-12 col-md-6 ps-md-2">
                                        <label for="tel" className="form-label">Numer telefonu</label>
                                        <input name="tel" type="tel" className="form-control" />
                                    </div>
                                </div>
                                <div className="d-flex flex-wrap w-100 ">
                                    <div className="mb-3 col-12 col-md-6 pe-md-2">
                                        <label for="function" className="form-label">Funkcja pełniona w kole</label>
                                        <input placeholder="np. członek zarządu" name="function" type="text" className="form-control" />
                                    </div>
                                    <div className="mb-3 col-12 col-md-6 ps-md-2">
                                        <label for="years" className="form-label">Lata członkostwa w kole</label>
                                        <input placeholder="np. 2012-2013" name="years" type="tel" className="form-control" />
                                    </div>
                                </div>
                                <div className="py-3">
                                    <p className="fs-5">Informacja o godzinach uczestnictwa w sympozjum <span className="bg-req">*</span></p>
                                    <div class="form-check">
                                        <input required name="how_long" value="whole" class="form-check-input" type="radio" />
                                        <label class="form-check-label" for="flexRadioDefault2">
                                            Będę na całym sympozjum
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input required name="how_long" value="officjal" class="form-check-input" type="radio" />
                                        <label class="form-check-label" for="flexRadioDefault2">
                                            Będę tylko na części oficjanej
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input required name="how_long" value="unofficial" class="form-check-input" type="radio" />
                                        <label class="form-check-label" for="flexRadioDefault2">
                                            Będę tylko na części nieoficjalnej
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input required name="how_long" value="another" class="form-check-input" type="radio" />
                                        <label class="form-check-label" for="flexRadioDefault2">
                                            Będę w innych godzinach (proszę o wiadomość)
                                        </label>
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <label for="message" class="form-label">Wiadomość do organizatorów</label>
                                    <textarea name="message" class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                                </div>
                                <div className="mb-3 form-check">
                                    <input required type="checkbox" className="form-check-input" id="exampleCheck1" />
                                    <label className="form-check-label" for="exampleCheck1">Wyrażam zgodę na przetwarzanie danych osobowych zgodnie z ustawą o ochronie danych osobowych w związku z wysłaniem zapytania przez formularz kontaktowy. Podanie danych jest dobrowolne, ale niezbędne do przetworzenia zapytania. Zostałem poinformowany, że przysługuje mi prawo dostępu do swoich danych, możliwości ich poprawiania, żądania zaprzestania ich przetwarzania.<br /> Administratorem danych osobowych jest Koło naukowe Eledyn | Politechnika Opolska</label>
                                </div>
                                <button ref={button} type="submit" className="btn btn-primary px-4 mb-4">Zapisz się</button>
                                <p><span className="bg-req">*</span> - pole wymagane, w razie pytań proszę o kontakt</p>
                                <a className="fs-6" href="mailto:l.kepa@student.po.edu.pl">l.kepa@student.po.edu.pl</a>
                            </form>
                            <div className="info">
                                <h2>Wiadomość została wysłana</h2>
                            </div>
                            <div className="error">
                                <h2>Nie udało się wysłać zgłoszenia</h2>
                                <p>Spróbuj ponownie, lub skontaktuj się z Nami</p>
                                <a className="fs-6" href="mailto:l.kepa@student.po.edu.pl">l.kepa@student.po.edu.pl</a>
                                <br />
                                <button onClick={() => { window.location.reload() }} type="button" class="mt-4 btn btn-dark">Odśwież</button>

                            </div>
                        </div>

                    </div>




                    <h2 >
                        2. Uiścić wpłatę w wysokości 200zł, przelewem na poniższe dane
                    </h2>
                    <p className="mt-4">Oddział Opolski SEP</p>
                    <p>
                        45-061 Opole,  ul. Katowicka 50
                    </p>
                    <p>
                        tytuł : Sympozjum Eledyn, imię i nazwisko
                    </p>
                    <p>
                        Nr konta: 45 1020 3668 0000 5602 0009 6545
                    </p>
                </div>

            </div>
        </div >
    )
}
export default Zapisy