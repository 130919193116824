import { useState, useEffect } from "react";
const Scrollanimations = (scrollTop, setScrollTop) => {

  const onScroll = () => {
    // This will calculate how many pixels the page is vertically
    const winScroll = document.documentElement.scrollTop;
    // This is responsible for subtracticing the total height of the page - where the users page is scrolled to
    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;

    // %%% strony
      const scrolled = (winScroll / height) * 100;
      setScrollTop(scrolled);

  };

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    // 
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

}

export default Scrollanimations;